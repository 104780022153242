import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  InputAdornment,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Typography,
  Autocomplete,
  Box,
  InputLabel,
  OutlinedInput,
  Button,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { saveDropdownPosition } from "../../../../store/posts/actions";
import { showError } from "../../../toastify";

const qualificationLevels = [
  { id: 1, name: "A2" },
  { id: 2, name: "A1" },
  { id: 3, name: "A0" },
];

const AddOrUpdatePositionsDropdownDialog = (props) => {
  const {
    loading,

    showDialog,
    setShowDialog,
    formData,
    setFormData,
    positionRoles,
    saveDropdownPosition,
    teachingLevels,
  } = props;

  const [errors, setErrors] = useState({
    roleHasError: false,
    nameHasError: false,
    qualificationHasError: false,
    teachingLevelHasError: false,
    hasError: false,
  });

  const formValidator = () => {
    const error = {
      roleHasError: false,
      nameHasError: false,
      qualificationHasError: false,
      teachingLevelHasError: false,
      ippisPositionCodeHasError: false,
      hasError: false,
    };

    if (!selectedRole) {
      error.roleHasError = true;
      error.hasError = true;
    }

    if (!selectedTeachingLevel) {
      error.teachingLevelHasError = true;
      error.hasError = true;
    }

    if (!formData.name) {
      error.nameHasError = true;
      error.hasError = true;
    }

    if (!selectedQualification) {
      error.qualificationHasError = true;
      error.hasError = true;
    }

    // if (!formData.ippisPositionCode) {
    //   error.ippisPositionCodeHasError = true;
    //   error.hasError = true;
    // }

    setErrors(error);

    if (error.hasError) {
      return true;
    }
    return false;
  };

  const onClose = () => {
    setShowDialog(false);

    setFormData({
      id: null,
      name: "",
      qualificationLevelId: "",
      classificationId: 1,
      teachingLevelId: "",
    });
  };

  const [selectedQualification, setSelectedQualification] = useState(
    qualificationLevels.find(
      ({ id }) => id === formData.qualificationLevelId
    ) || null
  );
  const [selectedRole, setSelectedRole] = useState(
    positionRoles.find(({ id }) => id === formData.classificationId) || null
  );

  const [selectedTeachingLevel, setSelectedTeachingLevel] = useState(
    teachingLevels.find(({ id }) => id === formData.teachingLevelId) || null
  );

  const onSave = () => {
    if (!formValidator()) {
      saveDropdownPosition(
        {
          ...formData,
          qualificationLevelId: selectedQualification.id,
          classificationId: selectedRole.id,
          teachingLevelId: selectedTeachingLevel?.id || null,
        },
        setFormData,
        setShowDialog
      );
    }
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showDialog}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle className="text-primary">
          <Typography
            variant="overline"
            className="text-truncate"
            display="block"
            style={{ maxWidth: "90%" }}
          >
            <span className="text-primary font-weight-bold">
              {!!formData.id
                ? "Edit Position Dropdown"
                : "Add New Position Dropdown"}
            </span>{" "}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <div className="row">
            <div className="col-8">
              <TextField
                autoFocus
                fullWidth
                size="small"
                name="positionName"
                label="Position Name"
                variant="outlined"
                placeholder="Position Name"
                value={formData?.name || ""}
                onChange={(e) => {
                  setErrors({
                    ...errors,
                    nameHasError: false,
                  });

                  setFormData({
                    ...formData,
                    name: e.target.value || "",
                  });
                }}
              />
              {errors.nameHasError && (
                <small className="text-danger ">
                  Position name is required
                </small>
              )}
            </div>

            <div className="col-4">
              <Autocomplete
                size="small"
                defaultValue={null}
                value={selectedQualification}
                options={qualificationLevels}
                onChange={(event, qualification) => {
                  setErrors({ ...errors, qualificationHasError: false });
                  setSelectedQualification(qualification || null);
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.name}
                renderOption={(props, qualification) => (
                  <Box component="li" {...props}>
                    {qualification.name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Qualification"
                    name="qualification"
                    placeholder="Qualification"
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
              />

              {errors.qualificationHasError && (
                <small className="text-danger ">
                  Qualification is required
                </small>
              )}
            </div>

            <div className="mt-3 col-6">
              <Autocomplete
                size="small"
                defaultValue={null}
                value={selectedTeachingLevel || null}
                options={teachingLevels}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event, teachingLevel) => {
                  setErrors({ ...errors, teachingLevelHasError: false });
                  setSelectedTeachingLevel(teachingLevel || null);
                }}
                getOptionLabel={(option) => option.name}
                renderOption={(props, teachingLevel) => (
                  <Box component="li" {...props}>
                    {teachingLevel.name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Teaching Level"
                    name="teachingLevel"
                    placeholder="Teaching Level"
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
              />

              {errors.teachingLevelHasError && (
                <small className="text-danger ">
                  Teaching Level is required
                </small>
              )}
            </div>

            <div className="mt-3 col-6">
              <Autocomplete
                size="small"
                defaultValue={null}
                value={selectedRole || null}
                options={positionRoles}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event, role) => {
                  setErrors({ ...errors, roleHasError: false });
                  setSelectedRole(role || null);
                }}
                getOptionLabel={(option) => option.name}
                renderOption={(props, role) => (
                  <Box component="li" {...props}>
                    {role.name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Position role"
                    name="positionRole"
                    placeholder="Position role"
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
              />

              {errors.roleHasError && (
                <small className="text-danger ">
                  Position role is required
                </small>
              )}
            </div>

            <div className="col-12 mt-3">
              <TextField
                fullWidth
                size="small"
                name="ippisPositionCode"
                label="IPPIS Position Code"
                variant="outlined"
                placeholder="IPPIS Position Code"
                value={formData?.ippisPositionCode || ""}
                onChange={(e) => {
                  setErrors({
                    ...errors,
                    ippisPositionCodeHasError: false,
                  });

                  setFormData({
                    ...formData,
                    ippisPositionCode: e.target.value || "",
                  });
                }}
              />
              {errors.ippisPositionCodeHasError && (
                <small className="text-danger ">
                  IPPIS position code is required
                </small>
              )}
            </div>
          </div>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center py-4">
          <Button
            variant="contained"
            onClick={onSave}
            type="button"
            className="px-4"
            disabled={loading}
          >
            {loading ? "Wait..." : "Save"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({
  loading,
  onWait,
  unitTypes,
  units,
  user,
  sectors,
  cells,
  villages,
  schoolLevels,
  jobs,
  selectedUnit,
  positionRoles,
  teachingLevels,
}) => {
  return {
    loading,
    onWait,
    unitTypes,
    units,
    user,
    sectors,
    cells,
    villages,
    schoolLevels,
    jobs,
    selectedUnit,
    positionRoles,
    teachingLevels,
  };
};
export default connect(mapStateToProps, {
  saveDropdownPosition,
})(AddOrUpdatePositionsDropdownDialog);
