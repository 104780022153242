import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";

import CssBaseline from "@mui/material/CssBaseline";
import SearchBox from "../../common/components/SearchBox";
import {
  Typography,
  Box,
  Button,
  Menu,
  MenuItem,
  IconButton,
  Badge,
  Divider,
  TextField,
  Tooltip,
  Autocomplete,
} from "@mui/material";
import { connect } from "react-redux";
import { CardContent, Toolbar } from "@mui/material";
import { useHistory } from "react-router-dom";
import {
  getPlacementReports,
  generatePlacementReport,
  deletePlacementReport,
  downloadAllPlacementReports,
} from "../../../../store/placement/actions";
import ReactPaginate from "react-paginate";
// import PostRequestDetailsDialog from "../components/PostRequestDetailsDialog";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import PlacementReportDetailsDialog from "../components/PlacementReportDetailsDialog";
import axios from "axios";
import { TreeItem, TreeView } from "@mui/lab";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const NewPlacementReports = (props) => {
  useEffect(() => {
    document.title = "TMIS | Post Management - Post request";
  }, []);

  const {
    user,
    loading,
    placementReports,
    generatePlacementReport,
    deletePlacementReport,
    getPlacementReports,
    downloadAllPlacementReports,
  } = props;

  const [schoolTeachingLevels, setSchoolTeachingLevels] = useState([]);
  const [teachingLevel, setTeachingLevel] = useState(null);

  const [confirmGenerateReport, setConfirmGenerateReport] = useState(false);
  const [onGeneratePlacementReport, setOnGeneratePlacementReport] =
    useState(false);

  const getSchoolTeachingLevels = async () => {
    try {
      const { data } = await axios.get("/api/placement/school-teaching-levels");

      setSchoolTeachingLevels(data);

      if (data.length === 1) setTeachingLevel(data[0]);
      else setTeachingLevel(null);
    } catch (error) {}
  };

  useEffect(() => {
    getPlacementReports("New");
  }, [user?.selectedEntity, user?.selectedAcademicYear]);

  useEffect(() => {
    if (!!confirmGenerateReport) getSchoolTeachingLevels();
    else setTeachingLevel(null);
  }, [confirmGenerateReport]);

  const handleToggleNode = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  const [expanded, setExpanded] = useState([]);

  useEffect(() => {
    if (!!placementReports.length)
      setExpanded([
        `${placementReports[0].districtId}`,
        `${placementReports[0].districtId}-${placementReports[0].periodId}`,
      ]);
  }, [placementReports]);

  const formatList = () => {
    const districts = [];

    placementReports.forEach((school) => {
      const districtIndex = districts.findIndex(
        ({ districtId }) => districtId === school.districtId
      );

      if (districtIndex >= 0) districts[districtIndex].schools.push(school);
      else
        districts.push({
          districtId: school.districtId,
          districtName: school.districtName,
          schools: [school],
        });
    });

    return districts;
  };

  const [periodId, setPeriodId] = useState("");
  const [district, setDistrict] = useState({});

  return (
    <>
      <div className="px-4 pt-2">
        <div className="border rounded">
          <AppBar position="static" elevation={0} className="app-bar w-100">
            <Toolbar
              style={{
                minHeight: "0px",
                borderBottom: "3px solid #e9ecef",
              }}
              className=" my-0 py-1 bg-light"
            >
              <Typography
                variant="p"
                noWrap
                component="div"
                className="d-flex  ilign-items-center text-uppercase text-primary font-weight-bold"
              >
                <span className="text-dark ">
                  ({placementReports.length}) New Reports{" "}
                </span>
                <span className="material-icons" style={{ color: "#cecece" }}>
                  chevron_right
                </span>

                <span
                  className="d-block text-truncate"
                  style={{ maxWidth: "70%" }}
                >
                  {user?.selectedEntity?.name}{" "}
                  {!!user?.selectedEntity?.type &&
                  !(user?.selectedEntity?.name)
                    .toUpperCase()
                    .includes(user?.selectedEntity?.type)
                    ? user?.selectedEntity?.type
                    : ""}
                  {user?.selectedEntity?.id === "00000000" && <>S</>}
                </span>
              </Typography>

              <Box sx={{ flexGrow: 1 }} />
              <span className="text-uppercase mr-2">
                {placementReports[0]?.periodName}{" "}
              </span>
              {user?.selectedEntity?.role === "HEAD_TEACHER" && (
                <Button
                  className="ml-2"
                  disabled={loading}
                  onClick={() => {
                    setConfirmGenerateReport(true);
                  }}
                  variant="contained"
                  size="small"
                  disableElevation
                  color="primary"
                  style={{
                    borderRadius: "8px",
                    height: "32px",
                    alignItems: "center",
                    fontSize: ".75rem",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    textTransform: "initial",
                  }}
                >
                  {!placementReports.length && (
                    <span className="material-icons">add</span>
                  )}
                  {!!placementReports.length && (
                    <span className="material-icons">edit_note</span>
                  )}
                  {!!placementReports.length
                    ? "Update Placement "
                    : "Generate New Placement"}
                </Button>
              )}
            </Toolbar>
          </AppBar>

          <div
            style={{
              backgroundColor: "#f3f4f6",
              borderRadius: "0.5rem",
              boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
              MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
              height: "69vh",
              overflowY: "auto",
            }}
            className="px-3"
          >
            <TreeView
              aria-label="controlled"
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
              expanded={expanded}
              onNodeToggle={handleToggleNode}
            >
              {formatList().map((district, index1) => (
                <TreeItem
                  key={`${district.districtId}`}
                  className={`nav-item bg-white mb-2`}
                  nodeId={`${district.districtId}`}
                  label={
                    <span
                      className="d-flex justify-content-between  align-items-center"
                      style={{ maxWidth: "100%" }}
                    >
                      <span
                        className={`d-block font-weight-light text-uppercase`}
                      >
                        <span
                          className="mr-2"
                          style={{
                            cursor: "default",
                          }}
                        >
                          {index1 + 1}: {district.districtName} DISTRICT{" "}
                          <small>({district?.schools?.length})</small>
                        </span>
                      </span>

                      <span>
                        {user?.selectedScope?.id !== "RTB" && (
                          <span className="badge badge-pill font-weight-light text-primary mr-1">
                            {
                              district?.schools?.filter(
                                ({ teachingLevelId }) => teachingLevelId === 1
                              ).length
                            }{" "}
                            Pre-primary
                          </span>
                        )}
                        {user?.selectedScope?.id !== "RTB" && (
                          <span className="badge badge-pill font-weight-light text-primary mr-1">
                            {
                              district?.schools?.filter(
                                ({ teachingLevelId }) => teachingLevelId === 2
                              ).length
                            }{" "}
                            Primary
                          </span>
                        )}
                        {user?.selectedScope?.id !== "RTB" && (
                          <span className="badge badge-pill font-weight-light text-primary mr-1">
                            {
                              district?.schools?.filter(
                                ({ teachingLevelId }) => teachingLevelId === 3
                              ).length
                            }{" "}
                            Secondary
                          </span>
                        )}
                        {user?.selectedScope?.id === "RTB" && (
                          <span className="badge badge-pill font-weight-light text-primary mr-1">
                            {
                              district?.schools?.filter(
                                ({ teachingLevelId }) => teachingLevelId === 4
                              ).length
                            }{" "}
                            TVET
                          </span>
                        )}
                        {(user?.selectedEntity?.type === "DISTRICT" ||
                          user?.selectedEntity?.id === "00000000") && (
                          <Tooltip title="Download">
                            <span className="p-0 text-center btn">
                              <IconButton
                                disabled={loading || !district.schools}
                                // size="small"
                                className="ml-2 menu-header-icon"
                                aria-label="settings"
                                color="primary"
                                onClick={(e) => {
                                  setDistrict({
                                    id: district.districtId,
                                    name: district.districtName,
                                  });
                                  setPeriodId(placementReports[0]?.periodId);
                                  setOnGeneratePlacementReport(true);

                                  e.stopPropagation();
                                }}
                              >
                                <Badge color="info">
                                  <span
                                    className="material-icons"
                                    style={{ fontSize: "18px" }}
                                  >
                                    download
                                  </span>
                                </Badge>
                              </IconButton>
                            </span>
                          </Tooltip>
                        )}
                      </span>
                    </span>
                  }
                >
                  <PlacementList
                    placementReports={district.schools}
                    deletePlacementReport={deletePlacementReport}
                    user={user}
                  />

                  <div className="ml-n3">
                    <Divider />
                    <Divider />
                  </div>
                </TreeItem>
              ))}
            </TreeView>

            {!placementReports?.length && (
              <div className="jumbotron jumbotron-fluid text-center">
                <div className="container py-5 ">
                  <p className="lead">
                    {loading ? "Fetching data..." : "No record found"}{" "}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {confirmGenerateReport && (
        <ConfirmationDialog
          disabled={!teachingLevel}
          confirmationDialog={confirmGenerateReport}
          message={
            <>
              <strong className="text-primary">Are you sure,</strong>
              <p className="mb-0"> You want to generate placement report?</p>

              <div className="mt-3">
                <Autocomplete
                  fullWidth
                  size="small"
                  defaultValue={null}
                  value={teachingLevel || null}
                  options={schoolTeachingLevels}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(event, teachingLevel) => {
                    setTeachingLevel(teachingLevel || null);
                  }}
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, teachingLevel) => (
                    <Box component="li" {...props}>
                      {teachingLevel.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Teaching level"
                      name="teachingLevel"
                      placeholder="Teaching level"
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                />
              </div>
            </>
          }
          btnMessage={"Generate"}
          setConfirmationDialog={setConfirmGenerateReport}
          onYes={() => {
            generatePlacementReport(
              teachingLevel?.id,
              setConfirmGenerateReport
            );
          }}
        />
      )}

      {onGeneratePlacementReport && (
        <ConfirmationDialog
          disabled={!teachingLevel}
          confirmationDialog={onGeneratePlacementReport}
          message={
            <>
              <p className="mb-0 mt-3 text-uppercase text-primary">
                Download Placement Report
              </p>

              <div className="mt-3">
                <Autocomplete
                  fullWidth
                  size="small"
                  defaultValue={null}
                  value={teachingLevel || null}
                  options={
                    user.selectedScope.id === "RTB"
                      ? [{ id: 4, name: "TVET" }]
                      : user.selectedEntity.role === "DEO_1"
                      ? [
                          { id: 1, name: "PRE_PRIMARY" },
                          { id: 2, name: "PRIMARY" },
                        ]
                      : user.selectedEntity.role === "DEO_2"
                      ? [{ id: 3, name: "SECONDARY" }]
                      : [
                          { id: 1, name: "PRE_PRIMARY" },
                          { id: 2, name: "PRIMARY" },
                          { id: 3, name: "SECONDARY" },
                        ]
                  }
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(event, teachingLevel) => {
                    setTeachingLevel(teachingLevel || null);
                  }}
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, teachingLevel) => (
                    <Box component="li" {...props}>
                      {teachingLevel.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Teaching level"
                      name="teachingLevel"
                      placeholder="Teaching level"
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                />
              </div>
            </>
          }
          btnMessage={"Download"}
          setConfirmationDialog={setOnGeneratePlacementReport}
          onYes={() => {
            downloadAllPlacementReports(
              {
                districtId: district?.id,
                districtName: district?.name,
                teachingLevelId: teachingLevel?.id,
                periodId: periodId,
              },
              setOnGeneratePlacementReport
            );
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ user, loading, placementReports }) => {
  return { user, loading, placementReports };
};
export default connect(mapStateToProps, {
  getPlacementReports,
  generatePlacementReport,
  deletePlacementReport,
  downloadAllPlacementReports,
})(NewPlacementReports);

const PlacementList = (props) => {
  const { placementReports, deletePlacementReport, user } = props;

  const [searchTerm, setSearchTerm] = useState("");
  const onSearch = (term) => {
    setSearchTerm(term);
  };

  const [filteredPlacementReports, setFilteredPlacementReports] = useState([
    ...placementReports,
  ]);

  useEffect(() => {
    const filtered = [...placementReports].filter(
      ({
        teachingLevelName,
        schoolName,
        schoollevelName,
        sectorName,
        districtName,
        periodName,
        periodId,
        statusName,
      }) =>
        (
          (teachingLevelName || "") +
          (schoolName || "") +
          (schoollevelName || "") +
          (sectorName || "") +
          (districtName || "") +
          (periodName || "") +
          (periodId || "") +
          (statusName || "")
        )
          .replace(/\s/g, "")
          .toLowerCase()
          .includes(searchTerm.toLowerCase().replace(/\s/g, ""))
    );
    setFilteredPlacementReports(filtered);

    paginate(filtered);
  }, [searchTerm, placementReports]);

  //PAGINATION
  const [paginatedItems, setPaginatedItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);

  const [itemOffset, setItemOffset] = useState(0);

  const itemsPerPage = 10;

  const paginate = (items) => {
    const endOffset = itemOffset + itemsPerPage;
    setPaginatedItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  };

  useEffect(() => {
    paginate(filteredPlacementReports);
  }, [itemOffset, itemsPerPage, filteredPlacementReports]);

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % filteredPlacementReports.length;

    setItemOffset(newOffset);
  };

  const getNo = (data, key, idToSearch) =>
    data.findIndex((item) => item[key] === idToSearch);

  return (
    <>
      {!!placementReports.length && (
        <table className="table table-striped table-hover table-sm">
          <thead>
            <tr>
              <th
                scope="col"
                rowSpan={2}
                style={{
                  borderLeft: "solid 1px #c6c7ce",
                  borderBottom: "2px solid #a8b6c3",
                  borderTop: "1px solid #a8b6c3",
                }}
              >
                #
              </th>

              <th
                scope="col"
                rowSpan={2}
                style={{
                  borderLeft: "solid 1px #c6c7ce",
                  backgroundColor: "#dbeafe",
                  borderBottom: "2px solid #a8b6c3",
                  borderTop: "1px solid #a8b6c3",
                }}
              >
                SCHOOLS
              </th>
              <th
                rowSpan={2}
                className="text-left text-uppercase"
                style={{
                  backgroundColor: "#d1def1",
                  borderLeft: "solid 1px #c6c7ce",
                  borderBottom: "2px solid #a8b6c3",
                  borderTop: "1px solid #a8b6c3",
                }}
              >
                TEACHING LEVEL
              </th>
              <th
                colSpan={3}
                style={{
                  backgroundColor: "#dbeafe",
                  borderLeft: "solid 1px #c6c7ce",
                  borderBottom: "2px solid #a8b6c3",
                  borderTop: "1px solid #a8b6c3",
                }}
                className="text-center text-uppercase"
              >
                Budget Posts
              </th>
              <th
                colSpan={3}
                style={{
                  backgroundColor: "#d1fae5",
                  borderLeft: "solid 1px #c6c7ce",
                  borderBottom: "2px solid #a8b6c3",
                  borderTop: "1px solid #a8b6c3",
                }}
                className="text-center text-uppercase"
              >
                Active Teachers
              </th>
              <th
                colSpan={3}
                style={{
                  backgroundColor: "#fef3c7",
                  borderLeft: "solid 1px #c6c7ce",
                  borderRight: "solid 1px #c6c7ce",
                  borderBottom: "2px solid #a8b6c3",
                  borderTop: "1px solid #a8b6c3",
                }}
                className="text-center text-uppercase"
              >
                Vacant Posts
              </th>
              <th colSpan={2}>
                <SearchBox onSearch={onSearch} placeholder="Search…" />
              </th>
            </tr>
            <tr style={{ backgroundColor: "#f0f2f5" }}>
              <th
                className="text-center"
                scope="col"
                style={{
                  backgroundColor: "#dbeafe",
                  borderLeft: "solid 1px #c6c7ce",
                  borderRight: "solid 1px #c6c7ce",
                  borderBottom: "2px solid #a8b6c3",
                }}
              >
                A2
              </th>

              <th
                className="text-center"
                scope="col"
                style={{
                  backgroundColor: "#dbeafe",
                  borderRight: "solid 1px #c6c7ce",
                  borderBottom: "2px solid #a8b6c3",
                }}
              >
                A1
              </th>

              <th
                className="text-center"
                scope="col"
                style={{
                  backgroundColor: "#dbeafe",
                  borderRight: "solid 1px #c6c7ce",
                  borderBottom: "2px solid #a8b6c3",
                }}
              >
                A0
              </th>

              <th
                className="text-center"
                scope="col"
                style={{
                  backgroundColor: "#d1fae5",
                  borderRight: "solid 1px #c6c7ce",
                  borderBottom: "2px solid #a8b6c3",
                }}
              >
                A2
              </th>

              <th
                className="text-center"
                scope="col"
                style={{
                  backgroundColor: "#d1fae5",
                  borderRight: "solid 1px #c6c7ce",
                  borderBottom: "2px solid #a8b6c3",
                }}
              >
                A1
              </th>

              <th
                className="text-center"
                scope="col"
                style={{
                  backgroundColor: "#d1fae5",
                  borderRight: "solid 1px #c6c7ce",
                  borderBottom: "2px solid #a8b6c3",
                }}
              >
                A0
              </th>
              <th
                className="text-center"
                scope="col"
                style={{
                  backgroundColor: "#fef3c7",
                  borderRight: "solid 1px #c6c7ce",
                  borderBottom: "2px solid #a8b6c3",
                }}
              >
                A2
              </th>
              <th
                className="text-center"
                scope="col"
                style={{
                  backgroundColor: "#fef3c7",
                  borderRight: "solid 1px #c6c7ce",
                  borderBottom: "2px solid #a8b6c3",
                }}
              >
                A1
              </th>

              <th
                className="text-center"
                scope="col"
                style={{
                  borderRight: "solid 1px #c6c7ce",
                  backgroundColor: "#fef3c7",
                  borderBottom: "2px solid #a8b6c3",
                }}
              >
                A0
              </th>
              <th scope="col" className="text-center">
                Status
              </th>
              <th style={{ width: "64px" }} className="text-right">
                Actions
              </th>
            </tr>
          </thead>

          {!!paginatedItems && (
            <tbody>
              {paginatedItems.map((placementReport) => (
                <ItemCard
                  key={placementReport.id}
                  user={user}
                  index={getNo(placementReports, "id", placementReport.id)}
                  placementReport={placementReport}
                  deletePlacementReport={deletePlacementReport}
                />
              ))}
            </tbody>
          )}
        </table>
      )}
      {!!placementReports.length && (
        <div className="mb-3 mt-n2">
          <ReactPaginate
            breakLabel="..."
            nextLabel={
              <>
                <span className="pr-1 d-none d-md-inline">Next</span>
                <i className="fas fa-angle-double-right"></i>
              </>
            }
            previousLabel={
              <>
                <i className="fas fa-angle-double-left"></i>
                <span className="pl-1  d-none d-md-inline">Previous</span>
              </>
            }
            pageRangeDisplayed={3}
            marginPagesDisplayed={1}
            onPageChange={handlePageClick}
            pageCount={pageCount}
            renderOnZeroPageCount={null}
            containerClassName="pagination"
            pageLinkClassName="btn btn-outline-dark btn-sm mr-1 ml-1"
            previousLinkClassName="btn btn-outline-dark btn-sm mr-1 ml-1"
            nextLinkClassName="btn btn-outline-dark btn-sm ml-1"
            activeLinkClassName="active"
          />
        </div>
      )}
    </>
  );
};

const ItemCard = (props) => {
  const { placementReport, index, deletePlacementReport, user } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);

    event.stopPropagation();
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [confirmCancelReport, setConfirmCancelReport] = useState(false);
  const [showPreviewDialog, setShowPreviewDialog] = useState(false);

  return (
    <>
      <tr className={``}>
        <th
          scope="row"
          className="text-left"
          style={{
            borderLeft: "solid 1px #c6c7ce",
          }}
        >
          {index + 1}
        </th>

        <td
          className="text-uppercase"
          style={{
            borderLeft: "solid 1px #c6c7ce",
          }}
        >
          {placementReport.schoolName} / {placementReport.sectorName}
        </td>
        <td
          className="text-left"
          style={{
            borderLeft: "solid 1px #c6c7ce",
            borderRight: "solid 1px #c6c7ce",
          }}
        >
          {placementReport.teachingLevelName}
        </td>

        {/* <td
          className="text-center"
          style={{
            borderRight: "solid 1px #c6c7ce",
          }}
        >
          {placementReport.primary}
        </td>
        <td
          className="text-center"
          style={{
            borderRight: "solid 1px #c6c7ce",
          }}
        >
          {placementReport.secondary}
        </td> */}
        <td
          className="text-center"
          style={{
            borderRight: "solid 1px #c6c7ce",
          }}
        >
          {+placementReport.totalBudgetPostsA2 || "-"}
        </td>

        <td
          className="text-center"
          style={{
            borderRight: "solid 1px #c6c7ce",
          }}
        >
          {+placementReport.totalBudgetPostsA1 || "-"}
        </td>
        <td
          className="text-center"
          style={{
            borderRight: "solid 1px #c6c7ce",
          }}
        >
          {+placementReport.totalBudgetPostsA0 || "-"}
        </td>
        <td
          className="text-center"
          style={{
            borderRight: "solid 1px #c6c7ce",
          }}
        >
          {+placementReport.totalTeachersA2 || "-"}
        </td>

        <td
          className="text-center"
          style={{
            borderRight: "solid 1px #c6c7ce",
          }}
        >
          {+placementReport.totalTeachersA1 || "-"}
        </td>
        <td
          className="text-center"
          style={{
            borderRight: "solid 1px #c6c7ce",
          }}
        >
          {+placementReport.totalTeachersA0 || "-"}
        </td>

        <td
          className="text-center"
          style={{
            borderRight: "solid 1px #c6c7ce",
          }}
        >
          {+placementReport.vacantBudgetPostsA2 || "-"}
        </td>

        <td
          className="text-center"
          style={{
            borderRight: "solid 1px #c6c7ce",
          }}
        >
          {+placementReport.vacantBudgetPostsA1 || "-"}
        </td>

        <td
          className="text-center"
          style={{
            borderRight: "solid 1px #c6c7ce",
          }}
        >
          {+placementReport.vacantBudgetPostsA0 || "-"}
        </td>
        <td
          className="text-center"
          style={{
            borderRight: "solid 1px #c6c7ce",
          }}
        >
          <span
            className={`p-2 badge badge-${
              placementReport.statusId === 1
                ? "light"
                : placementReport.statusId === 2
                ? "secondary"
                : placementReport.statusId === 4
                ? "info"
                : placementReport.statusId === 6
                ? "success"
                : "danger"
            }`}
          >
            {placementReport.statusName}
          </span>
        </td>
        <td
          className="text-right"
          style={{
            borderRight: "solid 1px #c6c7ce",
          }}
        >
          <IconButton
            size="small"
            className="ml-2"
            onClick={handleOpenMenu}
            aria-label="settings"
          >
            <span className="material-icons">more_vert</span>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={(e) => {
              handleCloseMenu();
              e.stopPropagation();
            }}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 2,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 15,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <span>
              <MenuItem
                onClick={(e) => {
                  handleCloseMenu();
                  setShowPreviewDialog(true);
                }}
                className="text-primary font-weight-bolder"
              >
                <span className="material-icons  mr-1">preview</span>
                Preview
              </MenuItem>
              <Divider className="my-1" />
            </span>

            {(placementReport.statusId === 1 ||
              (placementReport.statusId === 2 &&
                user.selectedEntity.role === "HEAD_TEACHER") ||
              placementReport.statusId === 3 ||
              placementReport.statusId === 3 ||
              ["REB", "RTB"].includes(user.selectedEntity.role)) && (
              <span>
                <MenuItem
                  onClick={(e) => {
                    handleCloseMenu();
                    setConfirmCancelReport(true);
                  }}
                  className="text-danger"
                >
                  <span className="material-icons mr-1">highlight_off</span>
                  Cancel
                </MenuItem>
              </span>
            )}
          </Menu>
        </td>
      </tr>

      {showPreviewDialog && (
        <PlacementReportDetailsDialog
          showDialog={showPreviewDialog}
          setShowDialog={setShowPreviewDialog}
          placementReport={placementReport}
          isEditable={
            (placementReport.statusId === 1 ||
              placementReport.statusId === 3 ||
              placementReport.statusId === 5) &&
            user.selectedEntity.role === "HEAD_TEACHER"
          }
        />
      )}

      {confirmCancelReport && (
        <ConfirmationDialog
          confirmationDialog={confirmCancelReport}
          message={
            <>
              <p className="mb-0 mt-3">
                Are you sure you want to{" "}
                <strong className="text-danger">cancel</strong> this placement
                report?
              </p>
            </>
          }
          // btnMessage={"Generate"}
          setConfirmationDialog={setConfirmCancelReport}
          onYes={() => {
            deletePlacementReport(placementReport.id, setConfirmCancelReport);
          }}
        />
      )}
    </>
  );
};
